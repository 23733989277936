/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <div className="bg-images-wrapper">
    <StaticImage
        src="../images/cosmic-lisa-thumb.jpg"
        className="absolute z-0 w-full max-w-none pointer-events-none select-none h-[500px] lg:h-[768px] blur-[80px] opacity-30 transition-opacity lisa-bg lisa-bg-0"
        alt="Cosmic Lisa Background"
        quality="50"
        loading="eager"
      />
      <StaticImage
        src="../images/cottage-lisa-thumb.jpg"
        className="absolute z-0 w-full max-w-none pointer-events-none select-none h-[500px] lg:h-[768px] blur-[80px] opacity-0 transition-opacity lisa-bg lisa-bg-1"
        alt="Cottage Lisa Background"
        quality="50"
        loading="eager"
      />
      <StaticImage
        src="../images/cyber-lisa-thumb.jpg"
        className="absolute z-0 w-full max-w-none pointer-events-none select-none h-[500px] lg:h-[768px] blur-[80px] opacity-0 transition-opacity lisa-bg lisa-bg-2"
        alt="Cyber Lisa Background"
        quality="50"
        loading="eager"
      />
      <StaticImage
        src="../images/deep-lisa-thumb.jpg"
        className="absolute z-0 w-full max-w-none pointer-events-none select-none h-[500px] lg:h-[768px] blur-[80px] opacity-0 transition-opacity lisa-bg lisa-bg-3"
        alt="Deep Lisa Background"
        quality="50"
        loading="eager"
      />
      <StaticImage
        src="../images/manga-lisa-thumb.jpg"
        className="absolute z-0 w-full max-w-none pointer-events-none select-none h-[500px] lg:h-[768px] blur-[80px] opacity-0 transition-opacity lisa-bg lisa-bg-4"
        alt="Manga Lisa Background"
        quality="50"
        loading="eager"
      />
      <StaticImage
        src="../images/meta-lisa-thumb.jpg"
        className="absolute z-0 w-full max-w-none pointer-events-none select-none h-[500px] lg:h-[768px] blur-[80px] opacity-0 transition-opacity lisa-bg lisa-bg-5"
        alt="Meta Lisa Background"
        quality="50"
        loading="eager"
      />
      <StaticImage
        src="../images/punk-lisa-thumb.jpg"
        className="absolute z-0 w-full max-w-none pointer-events-none select-none h-[500px] lg:h-[768px] blur-[80px] opacity-0 transition-opacity lisa-bg lisa-bg-6"
        alt="Punk Lisa Background"
        quality="50"
        loading="eager"
      />
      <StaticImage
        src="../images/utopia-lisa-thumb.jpg"
        className="absolute z-0 w-full max-w-none pointer-events-none select-none h-[500px] lg:h-[768px] blur-[80px] opacity-0 transition-opacity lisa-bg lisa-bg-7"
        alt="Utopia Lisa Background"
        quality="50"
        loading="eager"
      />
      <StaticImage
        src="../images/waifu-lisa-thumb.jpg"
        className="absolute z-0 w-full max-w-none pointer-events-none select-none h-[500px] lg:h-[768px] blur-[80px] opacity-0 transition-opacity lisa-bg lisa-bg-8"
        alt="Waifu Lisa Background"
        quality="50"
        loading="eager"
      />
    </div>
    
      
      <div className="relative z-10 overflow-hidden">
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="container mx-auto px-4 max-w-6xl">
          <main>{children}</main>
          <footer className="text-center pt-12 md:pt-16 pb-10 md:pb-12 opacity-50">
            © {new Date().getFullYear()}, Modern Lisas Project
          </footer>
        </div>
      </div>
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
